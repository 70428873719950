<template>

        <h1 class = "margin">
            <i class="fas fa-envelope grey-text"></i>
        </h1>
        <h6 class="grey-text">
            Vamos a contestar a su mensaje dentro de 24 horas
        </h6>
    
</template>

<style scoped>
    .fa-envelope{
        font-size: 120px;
    }
</style>