<template>
    <div class="container">

        <div class="row white marginTop" v-if="isSuccess">
            <Success title="Mensaje Enviado" @goBack="isSuccess=false">
                <ContactSuccess />
            </Success>
        </div>
        
        <div class="row white marginTop" v-else>
            
            <div class="col s12 center orange white-text">
                <h5>Contactenos</h5>
            </div>
            
            <div class="col s12 left-align grey darken-1 white-text">
                <p class = "margin">
                    Para cualquier pregunta contactenos usando el formulario abajo.<br>
                    Vamos a contestar dentro de 24 Horas.
                </p>
            </div>

            <ContactForm @msgSent="isSuccess=true"/>

        </div>

    </div>
</template>
    

<script>

import ContactForm from '@/components/Contacts/ContactForm'
import ContactSuccess from '@/components/Contacts/ContactSuccess'
import Success from '@/components/UI/Success'
import useBoilerplate from '@/composables/useBoilerplate';

import { ref } from '@vue/reactivity'

export default {

    components: { ContactForm, ContactSuccess, Success },

    setup(){
        const { isSuccess } = useBoilerplate(false);
        return { isSuccess }
    }
}
</script>

<style scoped>
    .container{
        max-width: 450px;
    }
</style>