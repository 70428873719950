<template>
    <div class="col s12 center grey darken-2 white-text">
        
        <form @submit.prevent="enquire" class = "margin">
            
            <div class = 'col s12 center-align input-field'>
                <input type = 'email' v-model='email' required>
                <label for='email'><i class='far fa-envelope'></i> Correo</label>
            </div>
            
            <div class = 'col s12 center-align input-field'>
                <textarea v-model='message' class='materialize-textarea' data-length='1000' required></textarea>
                <label for='message'><i class='fas fa-pen'></i> Mensaje</label>
            </div>
            
            <Loader v-if="isLoading" />
            
            <div class = 'col s12 center-align margin'>
                <button class = 'btn white-text orange'>
                    <i class='fas fa-check'></i>
                </button>
            </div>
        
        </form>

    </div>

    <teleport to="body">
        <ErrorModal :isActive="isActive" :warning="error" @close="isActive=false" />
    </teleport>

</template>

<script>

import ErrorModal from '@/components/modals/ErrorModal'
import Loader from '@/components/UI/Loader'
import { check_email, check_message, check_nauta } from '@/composables/sanitize'
import { ref } from '@vue/reactivity'
import { post } from '@/composables/httpCalls';
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { ErrorModal, Loader },
    emits: ['msgSent'],

    setup(props, context){

        const { isLoading, isActive, error } = useBoilerplate(false);

        const email = ref('');
        const message = ref('');
        

        const enquire = async () => {

            error.value = null;

            if(!check_nauta(email.value) ){
                error.value = 'No Es Posible Usar el Correo NAUTA.';
                return isActive.value = true;
            }

            if(!check_email(email.value) ){
                error.value = 'El Correo no es Valido';
                return isActive.value = true;
            }

            if(!check_message(message.value) ){
                error.value = 'Es Necesario Enviar un Mensaje (hasta 1000 characteres)';
                return isActive.value = true;
            }
            
            isLoading.value = true;
            
            const url = "/api/enquiry";
            
            const vars = {
                sender: email.value.toLowerCase().replace(/ /gi, ""),
                message: message.value
            }

            try {
                
                const res = await post(url, vars);
                isLoading.value = false;

                if(res.status == 200){
                    return context.emit('msgSent');
                }else{
                    error.value = "No Se Pudo Enviar el Correo";
                    isActive.value = true;
                }

            } catch (err) {
                console.log(err);
                error.value = err;
                isLoading.value = false;
                isActive.value = true;
            }
        
        }

        return {
            email, message, isLoading, isActive, error, enquire
        }
    }
}
</script>

<style scoped>
    textarea{
        color: white !important;
    }
</style>